import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from 'grommet';

const DynamicProductDescription = ({ blok, ...rest }) => {
  return blok.product?.item?.description || blok.product?.description ? (
    <Paragraph {...rest}>
      {blok.product?.item?.description ||
        blok.product?.description ||
        rest.product?.description ||
        rest.product?.item?.description}
    </Paragraph>
  ) : null;
};

DynamicProductDescription.propTypes = {
  product: PropTypes.object,
  blok: PropTypes.shape({
    product: PropTypes.object,
  }),
};

export default DynamicProductDescription;
